const MemberDashboard = () => {
    return (
    <div className='content-wrapper'>
        <div className="p-4 flex flex-row flex-wrap mx-auto ml-4 md:ml-48 gap-20" >
            <h1>Coming Soon</h1>
        </div>
    </div>
    )
}

export default MemberDashboard;